<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Playa Resorts Layout',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader('a27adfd1-a837-4704-97c4-5b910df8afc6')

const {
  currentResort,
} = await getCommonLayoutData(isVoixTemplatizer, [
  'resorts',
  'brands',
  'footerCompanyMenu',
  'footerResourcesMenu',
  'footerTermsMenu',
])

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#0a1c36' })
provide('currentResort', currentResort.value.data)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white playaresorts-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />
        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="{
              stacked: false,
              menuPosition: 'right', // right or center
              logoPosition: 'left',
              backgroundClass: 'bg-white',
              paper: false,
              bookNowBackground: 'bg-[#024263]',
              allResorts: false,
              booking: false,
              hideMobileBooking: true,
              customButton: true,
            }"
          >
            <div class="p-4 bg-collection-teal flex items-center uppercase text-sm">
              <a href="https://members.theplayacollection.com/" target="_blank" class="text-white">
                Member Access
              </a>
            </div>
          </NavigationHeader>

          <slot />

          <div role="contentinfo">
            <div id="footer" name="footer">
              <div id="footer" name="footer" class="bg-white">
                <div class="flex flex-col justify-between p-4 text-xs">
                  <div>
                    <ul class="flex flex-col md:flex-row items-center list-reset">
                      <li class="mx-2">
                        <a href="https://theplayacollection.com/terms-conditions" class="text-xs text-grey-darker">
                          Terms &amp; Conditions
                        </a>
                      </li><li class="mx-2">
                        <a href="https://theplayacollection.com/privacy-policy" class="text-xs text-grey-darker">
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div> <div class="text-center mt-8">
                    © 2024 Teatree International LLC All rights reserved. Teatree International, LLC independently owns and operates The Playa Collection™ membership program. It uses The Playa Collection™ trademark under license from Playa Resorts Management, LLC and other marks through third-party sublicenses.  None of Hyatt Franchising Latin America, LLC, Hilton Worldwide Manage Limited, WHG Caribbean Holdings Inc., Playa Resorts Management, LLC (each of which is separate and not affiliated with each other) or their respective affiliates own or operate the program, and they make no representations, warranties, or guaranties with respect to The Playa Collection™.  Not all Hyatt Ziva™, Hyatt Zilara™, Hilton All-Inclusive or Wyndham Alltra™ resorts may participate in The Playa Collection™
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/playaresorts.scss';
</style>
